#testimonials {
    scroll-margin-top: 100px;

    h3 {
        text-align: center;
        margin-bottom: 20px;
        /* color: var(--color); */
    }
}

.container.testimonials__container {
    width: 70%;
    padding-bottom: 4rem;

    @media (min-width:1024px) {
        max-width: 100%;
        width: 100%;
    }
}


.client__avatar {
    width: 80px;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    height: 350px !important;
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto;

}


.swiper-button-prev {
    color: white !important;
    left: 10% !important;
}

.swiper-button-next {
    color: white !important;
}

@media screen and (max-width:1024px) {

    .container.container.testimonials__container {
        width: 100%;

    }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {

    .container.container.testimonials__container {
        width: var(--container-width-sm);


    }

    .client__review {
        width: var(--container-width-sm);
    }
}