#about {
    position: relative;
    overflow: visible;
    overflow-x: clip;
    scroll-margin-top: 100px;

}


.about__container {
    display: flex;
    justify-self: center;
    align-items: center;
    max-width: 90%;

    @media (min-width:1024) {
        max-width: 50%;
    }

}

.about__container h2 {
    text-align: center;
    margin-bottom: 20px;

    @media (min-width:1024px) {
        margin-bottom: 40px;
    }
}

.about__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.about__content p {
    color: var(--color-light);
    margin-bottom: 40px;
    text-align: center;

}

.about__image__wrapper {
    margin-top: 5%;
    height: 600px;
    position: relative;
}

.about__image {
    margin-top: 5%;
    height: 600px;
    position: relative;
}

.about__image::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    box-shadow: 0px 12px 200px 100px var(--color-bg-variant);
    z-index: -2;
}

.about__image img {
    width: 100%;
    height: 100%;
    opacity: 0.2;
    transition: 0.5s ease-in-out;
}

.about__image__desc {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    color: var(--color-white);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99;
    width: 100%;
}

.about__image__desc h2 {
    margin-bottom: 20px;
    margin-top: -60px;
    color: var(--color-white);

    @media (min-width:1024) {
        font-size: 60px;
        margin-bottom: 40px;
    }
}

.about__image__desc p {
    max-width: 90%;
    color: var(--color-white);
    margin: 0px auto;

    @media (min-width:1024px) {
        max-width: 70%;
    }

    @media (min-width:1600px) {
        max-width: 1440px;
    }

}

.about__bg__square--left {
    position: absolute;
    top: 0;
    left: -5%;
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    width: 100%;
    background-color: var(--color-bg-variant);
    opacity: 0.1;
    transform: rotate(135deg);
    transition: 0.3s ease-in-out;

    @media (min-width:1024px) {
        max-width: 250px;
        max-height: 250px;
    }

}

.about__bg__square--left:hover {
    transform: rotate(180deg);
}

.about__bg__square--right {
    position: absolute;
    top: 10%;
    right: -5%;
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    width: 100%;
    background-color: var(--color-bg-variant);
    opacity: 0.1;
    transform: rotate(135deg);
    transition: 0.3s ease-in-out;

    @media (min-width:1024px) {
        max-width: 250px;
        max-height: 250px;
    }
}

.about__bg__square--right :hover {
    transform: rotate(180deg);
}



/* ================= MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width:1024px) {

    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }

}

/* ================= MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and(max-width: 600px) {

    .about__me {
        width: 65%;
        margin: 0rem auto 3rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}