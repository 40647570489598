.blog__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blog__header {
    margin-top: 100px;
    margin-bottom: 100px;
}

.blog__wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 80px;
    width: 100%;
}

.blog__filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.blog__categories {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    gap: 10px;
}

.blog__categories button {
    background-color: transparent;
    border: 1px solid var(--color-bg-variant);
    color: var(--color-white);
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.blog__categories button:hover {
    background-color: var(--color-bg-variant);
}

.blog__categories button.active {
    background-color: var(--color-bg-variant);
}

.blog__post {
    width: 49%;
    margin-bottom: 30px;
    display: flex;
    border-radius: 15px;
    border: 1px solid var(--color-bg-variant);
    position: relative;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.blog__post:hover {
    background-color: var(--color-bg-variant);
}


.blog__post__category {
    display: flex;
    background-color: var(--color-bg-variant);
    padding: 5px 10px;
    color: var(--color-white);
    height: fit-content;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0px 15px;
    transition: 0s;
}


.blog__post img {
    border-radius: 15px;
    max-width: 200px;
    opacity: 1 !important;
    transform: none !important;
    margin-right: 20px;
}

.blog__post__content {
    padding: 10px;
}

.blog h5 {
    margin-top: 20px;
    color: var(--color-white);
    opacity: 1 !important;
    transform: none !important;
    margin-top: 30px;
    font-size: 18px;
    margin-bottom: 10px;
}

.blog p {
    opacity: 1 !important;
    transform: none !important;
    color: var(--color-white) !important;
}

.blog__post:hover .blog__post__category {
    background-color: var(--color-white);
    color: var(--color-bg-variant) !important;
}

.blog__search input {
    padding: 10px 15px !important;
}


@media (max-width:1024px) {
    .blog__filters {
        gap: 20px;
        flex-wrap: wrap;
    }

    .blog__categories {
        width: 100%;
        flex-wrap: nowrap;
        overflow-y: scroll;
    }

    .blog__categories button {
        width: fit-content;
        white-space: nowrap;
    }

    .blog__post {
        width: 100%;
    }
}

@media (max-width:600px) {
    .blog__post {
        width: 100%;
        flex-wrap: wrap;
    }

    .blog__post img {
        width: 100%;
        max-width: 100%;
    }
}