#portfolio {
    overflow: hidden;
    scroll-margin-top: 100px;

    @media (max-width:1024px) {
        padding: 0px 20px;
    }
}

.portfolio__container {
    display: flex;
    flex-wrap: nowrap;
    gap: 2.5rem;
    position: relative;
}

#portfolio p {
    margin: 0px auto;
    text-align: center;
    margin-bottom: 5%;

    @media (min-width:1024px) {
        max-width: 70%;
        width: 100%;
    }

    @media (min-width:1600px) {
        max-width: 1440px;
    }
}


.portfolio__items {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    margin: 0px auto;
    position: relative;

    @media (min-width:1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 80%;

    }
}

.portfolio__item {
    padding: 2rem;
    padding-bottom: 1rem;
    border: 1px solid transparent;
    transition: 0.4s ease;
    width: 100%;
    height: 100%;
}

.portfolio__item:hover .portfolio__item-image img {

    box-shadow: 0px 0px 10px 10px var(--color-bg);
    transition: 0.4s ease;

}


.portfolio__item-image img {
    border-radius: 2rem;

}

.portfolio__item-image ul li {
    font-size: 13px;
    padding-bottom: 20px;
}

.portfolio__item h3 {
    margin: 1.2 0 2rem;
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: 25px;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__image {
    max-width: 100%;
    margin: 0px auto;
    margin-bottom: 10%;
    margin-top: 40px;

    @media (min-width:1024px) {
        max-width: 70%;
        margin-top: 0px;

    }
}

.portfolio__image img {
    width: 100%;
}

.portfolio__bg__square--left {
    position: absolute;
    top: 0;
    left: -5%;
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    width: 100%;
    background-color: var(--color-bg-variant);
    opacity: 0.1;
    transform: rotate(135deg);
    transition: 0.3s ease-in-out;

    @media (min-width:1024px) {
        max-width: 250px;
        max-height: 250px;
    }
}

.portfolio__bg__square--left:hover {
    transform: rotate(180deg);
}

.portfolio__bg__square--right {
    position: absolute;
    top: 10%;
    right: -5%;
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    width: 100%;
    background-color: var(--color-bg-variant);
    opacity: 0.1;
    transform: rotate(135deg);
    transition: 0.3s ease-in-out;

    @media (min-width:1024px) {
        max-width: 250px;
        max-height: 250px;
    }

}

.portfolio__bg__square--right :hover {
    transform: rotate(180deg);
}



@media screen and (max-width:1024px) {

    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width:600px) {

    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;

    }


}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;
}

.popup-inner {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.popup h2 {
    margin-bottom: 15px;
    color: #000000;
}

.popup strong {
    font-size: 18px;
}

.popup h4 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #000000;
}

.popup p {
    margin-bottom: 25px;
    color: #000000;
}

.popup-close {
    background: var(--color-bg-variant);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.popup__buttons a {
    width: 100%;
}

.popup__buttons a:hover {
    width: 100%;
    background: var(--color-bg-variant);
    color: white;
}


.popup-close:hover {
    background: var(--color-bg-variant);
}