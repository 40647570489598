#contact {
    scroll-margin-top: 100px;

    @media (max-width:1024px) {
        padding: 0px 20px;
    }
}

#contact p {
    text-align: center;
}

.container.contact__container {
    width: 90%;
    display: grid;
    grid-template-columns: 30% 58%;

    @media (min-width:1024px) {
        width: 58%;
        gap: 12%;
    }
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: white;
}

/* ===================== FORM ========================== */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-bg-variant);
    resize: none;
    color: var(--color-white);
    font-family: 'Poppins', sans-serif !important;

}

.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.checkbox-container input {
    width: 30px;
}

@media screen and (max-width:1024px) {

    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

}

/* ================= MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and(max-width: 600px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        width: var(--container-width-sm);
    }
}