header {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background-color: var(--color-black);
    top: 0 !important;
}

.pin-spacer {
    margin-bottom: 105vw !important;
}

.header__nav.nav--home {
    display: flex;
    position: absolute;
    right: 15%;
    top: 2%;
    z-index: 9999999;
}

.header__nav {
    display: flex;
}


.header__logo {
    @media (max-width:1024px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.header__logo img {
    width: 150px;
}

.header__nav a {
    color: var(--color-white);
    margin-left: 20px;
    position: relative;
}

.header__nav a::before {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0;
    background-color: var(--color-white);
    height: 1px;
    left: 0;
    transition: 0.3s ease-in-out;
}

.header__nav a:hover::before {
    width: 100%;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header__wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    /* align-items: center; */
    flex-direction: column;
    height: 100vh;
    /* margin-top: 3%; */
}

.header__wrapper--blog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 65px;

    @media (max-width:1024px) {
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        gap: 40px;
    }
}

.header__wrapper--blog .header__nav {
    position: relative;
}

.header__bg {
    /* background-image: url(../../assets/bg1.jpg); */
    /* background-position: center; */
    /* background-size: cover; */
    opacity: 0.2;
    box-shadow: 0px 12px 200px 100px var(--color-bg-variant);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.header__bg video {
    position: relative;
    top: 0;
    bottom: 0;
    z-index: 5;

    @media (min-width:1900px) {
        width: 100vw;
    }
}

.header__first {
    text-align: start;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 0.3);
    /* transition: 1s ease-in-out; */
}

.header__second {
    text-align: center;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 0.3);
    /* transition: 1s ease-in-out; */

}

.header__third {
    text-align: start;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 0.3);
    /* transition: 1s ease-in-out; */
    margin-bottom: 40px;

}

.header h3 {
    transition: 1s ease-in-out;
}

.header h2 {
    transition: 1s ease-in-out;

}



/* =============== CTA ==================== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/* =============== HEADER SOCIALS ==================== */

.header__socials {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
    gap: 0.8rem;
    font-size: 30px;

}





/* =============== SCROLL DOWN ==================== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    font-weight: 300;
    font-size: 0.9rem;
}


/* ================= MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width:1024px) {
    .header {
        height: 68vh;
    }

    .header-blog {
        height: fit-content;
    }

}

/* ================= MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width:600px) {

    .header {
        height: 100vh;
        width: 100%;
    }

    .header-blog {
        height: fit-content;
    }


    .header__socials,
    .scroll__down {
        display: none;
    }
}