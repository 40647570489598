#realizations {
    position: relative;
    overflow: visible;
    overflow-x: clip;
    scroll-margin-top: 100px;

}

.realizations__container {
    display: flex;
    flex-wrap: nowrap;
    gap: 2.5rem;
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: auto;

    @media (max-width:600px) {
        padding-left: 20px !important;
    }

}

.realizations__wrapper p {
    width: 90%;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 5%;

    @media (min-width:1024px) {
        max-width: 70%;
        width: 100%;
    }

    @media (min-width:1600px) {
        max-width: 1440px;
    }
}

.realizations__wrapper a {
    color: var(--color-white) !important;
}

.realizations__wrapper h2 {
    color: var(--color-bg-variant);
    text-align: center;
    margin-bottom: 20px;

    @media (min-width:1024px) {
        margin-bottom: 40px;
    }
}


.realizations__items {
    display: flex;
    gap: 0px;
    width: 100%;
    margin: 0px auto;
    flex-wrap: wrap;
}

.realizations__item {
    border: 1px solid transparent;
    transition: 0.4s ease;
    width: 50%;
    transition: 0.5s ease-in-out !important;
    position: relative;
}

.realization__item a {
    color: white !important;
}

.realizations__text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10%;
    opacity: 0;
    z-index: 5;
    transition: 0.5s ease-in-out;
}

.realizations__text:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.795);
}

.realizations__text p {
    opacity: 1;
    color: var(--color-white);
    text-align: center;

}

.realizations__text h3 {
    opacity: 1;
}

.realizations__slide {
    width: 35% !important;
    transition: 0.5s ease-in-out !important;
    height: 100vh !important;
}

.realizations__slide:hover {
    width: 70% !important;
    transition: 0.5s ease-in-out !important;
    height: 100vh !important;

}


.realizations__item-image {
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    gap: 25px;
    height: 100%;
}

.realizations__item:hover .realizations__item-image img {

    box-shadow: 0px 0px 10px 10px var(--color-bg);
    transition: 0.4s ease;

}

.realizations__item__desc {
    width: 70%;
    display: none;
}

.realizations__slide__name {
    display: none;
}

.realizations__slide__review {
    display: none;
}

.realizations__item-image {
    position: relative;

}

.realizations__item-image::after {
    /* border-radius: 2rem; */
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.347);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

}

.realizations__item-image p {
    font-size: 20px;
    padding-bottom: 20px;
}

.realizations__item h3 {
    margin: 1.2 0 2rem;
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: 25px;
}

.realization a {
    color: var(--color-white) !important;
}

.realization__title {
    margin-top: 25px;
    margin-bottom: 20px;

    @media (min-width:1024px) {
        margin-bottom: 20px;
    }
}

.realization__desc {
    opacity: 1;

    @media (min-width:1024px) {
        padding-right: 15%;

    }

}

.realization__image {
    height: 180px;

    @media (min-width:600px) {
        height: 450px;
    }

    @media (min-width:1024px) {
        height: 650px;
    }

    img {
        height: 100%;
    }
}

.realizations__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.realizations__image {
    max-width: 70%;
    margin: 0px auto;
    margin-bottom: 10%;
}

.realizations__image img {
    width: 100%;
}

.realizations__slide__avatar {
    width: 100%;
    height: 100%;

}

.realizations__slide__avatar img {
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    visibility: visible !important;
    object-fit: cover;
}

.swiper-pagination-bullet {
    background: var(--color-white) !important;
}

.realizations__bg__square--left {
    position: absolute;
    top: 0;
    left: -5%;
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    width: 100%;
    background-color: var(--color-bg-variant);
    opacity: 0.1;
    transform: rotate(135deg);
    transition: 0.3s ease-in-out;

    @media (min-width:1024px) {
        max-width: 250px;
        max-height: 250px;
    }
}

.realizations__bg__square--left:hover {
    transform: rotate(180deg);
}

.realizations__bg__square--right {
    position: absolute;
    top: 10%;
    right: -5%;
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    width: 100%;
    background-color: var(--color-bg-variant);
    opacity: 0.1;
    transform: rotate(135deg);
    transition: 0.3s ease-in-out;

    @media (min-width:1024px) {
        max-width: 250px;
        max-height: 250px;
    }

}

.realizations__bg__square--right :hover {
    transform: rotate(180deg);
}


@media screen and (max-width:1024px) {

    .realizations__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width:600px) {

    .realizations__container {
        grid-template-columns: 1fr;
        gap: 1rem;

    }


}