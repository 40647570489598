@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {

    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}


:root {
    /*--color-bg: #1f1f38;*/
    --color-bg: #120024;
    --color-black: #000000;
    /*--color-bg-variant: #2c2c6c;*/
    --color-bg-variant: #30005f;
    /* --color-primary: #4db5ff;*/
    --color-primary: #3700ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --swiper-theme-color: var(--color-bg);
    --transition: all 0.3s ease;


    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

}

::-moz-selection {
    /* Code for Firefox */
    color: white;
    background: #1e003b;
}

::selection {
    color: white;
    background: #1e003b;
}

html {
    scroll-behavior: smooth;
    /* cursor: none; */
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif !important;
    background: var(--color-black);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture.png);
    /* cursor: none; */

}

.content {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}

.CybotCookiebotDialogActive button {

    background-color: black !important;
    color: white !important;
}

.CybotCookiebotDialogActive .CybotCookiebotDialogBodyButton {
    border: 1px solid white !important;
}

.CybotCookiebotDialogActive svg {

    display: none !important;
}

/* body.hidden {
    overflow-y: hidden;
    height: 100vh;
} */

/* .cursor {
    position: fixed;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    left: -100px;
    top: 50%;
    mix-blend-mode: difference;
    background-color: transparent;
    z-index: 10000;
    border: 2px solid white;
    height: 30px;
    width: 30px;
    transition: all 300ms ease-out;
} */


/* =============== GENERAL STYLES ========== */

.container {
    width: 90%;
    margin: 0 auto;

    @media (min-width:1024px) {
        width: var(--container-width-lg);
    }

}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;

}

h1 {

    font-size: 3rem;
    line-height: 4rem;

    @media (min-width:800px) {
        font-size: 5rem;
        line-height: 7rem;
    }

    @media (min-width:800px) {
        font-size: 8rem;
        line-height: 10rem;
    }

    @media (min-width:1600px) {
        font-size: 10rem;
        line-height: 15rem;
    }

    @media (min-width:2500px) {
        font-size: 12rem;
        line-height: 20rem;
    }


}

h2 {
    font-size: 2rem;
    line-height: 3rem;

    @media (min-width:800px) {
        font-size: 3rem;
        line-height: 4rem;
    }

    @media (min-width:1600px) {
        font-size: 5rem;
        line-height: 7rem;
    }
}

h3 {
    font-size: 1.5rem;
    line-height: 2rem;

    @media (min-width:800px) {
        font-size: 2rem;
        line-height: 3rem;
    }
}

section {
    margin-top: 4rem;

    @media (min-width:1024px) {
        margin-top: 8rem;

    }
}

section>h2,
section>h3,
section>h5 {

    text-align: center;
    color: var(--color-white);
}

section>h2 {
    color: var(--color-bg-variant);
    margin-bottom: 20px;

    @media (min-width:1024px) {
        margin-bottom: 40px;
    }
}

section>h3 {
    margin-bottom: 20px;

}

.text-light {
    color: var(--color-light);

}

a {
    color: var(--color-bg-variant);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

p {
    font-size: 16px;

    @media (min-width:1024) {
        font-size: 26px;
    }
}

.btn {
    width: max-content;
    display: block;
    color: var(--color-bg-variant);
    padding: 0.75rem 1.2rem;
    border-radius: 1em;
    cursor: pointer;
    border: 1px solid var(--color-bg-variant);
    transition: var(--transition);
    margin-bottom: 40px;
    margin-top: 40px;

}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-bg-variant);
    color: var(--color-white);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}


.breadcrumbs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    margin-bottom: 40px;
}

.breadcrumbs a {
    position: relative;
    color: white;
    padding-bottom: 2px;
}

.breadcrumbs a::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: white;
    transition: 0.3s ease-in-out;
}

.breadcrumbs a:hover::before {
    width: 100%;
}


/* ================= MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and(max-width:1024px) {

    .container {
        width: var(--container-width-md);

    }

    .section {
        margin-top: 6rem;
    }

}

/* ================= MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and(max-width:600px) {

    .container {
        width: var(--container-width-sm);

    }

    .section>h2 {
        margin-bottom: 2rem;
    }

}

h1 {
    transform: translateY(30%);
    opacity: 0;
    /* transition: 0.3s ease-in-out; */

}

h1.animate {
    opacity: 1;
    transform: translateY(0);
}

h2 {
    transform: translateY(30%);
    opacity: 0;
    transition: 1s ease-in-out;

}

h2.animate {
    opacity: 1;
    transform: translateY(0);
}

h3 {
    transform: translateY(30%);
    opacity: 0;
    transition: 1s ease-in-out;

}

h3.animate {
    opacity: 1;
    transform: translateY(0);
}

h4 {
    transform: translateY(30%);
    opacity: 0;
    transition: 1s ease-in-out;

}

h4.animate {
    opacity: 1;
    transform: translateY(0);
}

h5 {
    transform: translateY(30%);
    opacity: 0;
    transition: 1s ease-in-out;

}

h5.animate {
    opacity: 1;
    transform: translateY(0);
}

p {
    opacity: 0;
    transform: translateY(30%);
    transition: 1s ease-in-out;

}

p.animate {
    opacity: 1;
    transform: translateY(0);
}

img {
    opacity: 0;
    /* transform: translateY(30%); */
    transition: 1s ease-in-out;

}

img.animate {
    opacity: 1;
    /* transform: translateY(0); */
}